<template>
  <div>
    <el-row class="m-0" :gutter="20">
      <el-col :span="6">
        <!-- <el-button @click="handleCreate" type="text" icon="el-icon-circle-plus-outline">Добавить</el-button> -->
        <router-link :to="{ name: 'AdministrationUsefulDocumentsItem' }">
            <el-button type="text" icon="el-icon-circle-plus-outline" >Добавить</el-button>
        </router-link>
      </el-col>
      <el-col :span="12">
        <pagination
          style="text-align: center"
          :total-count="totalCount"
          :page-size.sync="pageSize"
          :current-page.sync="pageNumber"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-col>
    </el-row>
    <div>
      <el-table v-loading="loading"
                :data="usefulDocuments"
                style="width: 100%;"
                :height="tableHeight"
                :default-sort = "{prop: 'notificationTime', order: 'descending'}"
                @sort-change="handleSortChange">
        <el-table-column label="" width="50">
          <template slot-scope="props">
            <router-link :to="{ name: 'AdministrationUsefulDocumentsItem', params: { id: props.row.id } }">
                <el-button type="text" icon="el-icon-edit" />
            </router-link>
          </template>
        </el-table-column>
        <el-table-column prop="name"
                         label="Заголовок"
                         sortable="custom"
                         min-width="75">
        </el-table-column>
        <el-table-column prop="fileName"
                         label="Имя файла"
                         sortable="custom"
                         min-width="60">
        </el-table-column>
        <el-table-column 
                         label="Скачать"
                         min-width="20">
            <template slot-scope="scope">
                         <el-button type="text" icon="el-icon-download" @click="handleDownload(scope.row)">Скачать</el-button>
            </template>
        </el-table-column>
        <el-table-column label="" width="50">
            <template slot-scope="scopes">
                  <el-button type="text" icon="el-icon-delete"
                            @click="handleDelete(scopes.$index, scopes.row)"></el-button>
            </template>
          </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import usefulDocumentsApi from '@/api/usefuldocuments';
import pagination from '@/components/Ui/Pagination';
import formatters from '@/mixins/formatters.js';
import tableResize from '@/mixins/tableResize';

  export default {
    name: 'AdministrationUsefulDocuments',
    mixins: [formatters, tableResize],
    components: { pagination },
    data() {
      return {
        loading: false,
        usefulDocuments: [],
        pageNumber: 1,
        pageSize: 20,
        totalCount: 0,
        sortField: 'name',
        sortDesc: true
      };
    },    
    async mounted() {
      this.$nextTick(() => {
        this.tableHeight = this.getTableHeight();
      });
      await this.getAdministrationUsefulDocuments();
      this.tableHeight = this.getTableHeight();
    },
    methods: {
    ...mapActions('usefuldocuments', [
      'deleteUsefulDocuments'
    ]),
      async getAdministrationUsefulDocuments() {
        this.loading = true;
        const res = await usefulDocumentsApi.getAdministrationUsefulDocuments(
          this.pageNumber,
          this.pageSize,
          this.sortField,
          this.sortDesc
        );
        if (res.data) {
          this.usefulDocuments = res.data.items;
          this.totalCount = res.data.totalCount;
        }
        this.loading = false;
      },
      async handleSizeChange() {
        this.pageNumber = 1;
        await this.getAdministrationUsefulDocuments();
      },
      async handleCurrentChange() {
        await this.getAdministrationUsefulDocuments();
      },
      async handleSortChange(event) {
        this.sortField = event.prop;
        this.sortDesc = event.order === 'descending';
        await this.getAdministrationUsefulDocuments();
      },
      // eslint-disable-next-line no-unused-vars
      handleDelete(index, usefulDocuments) {
        this.$confirm('Вы действительно хотите удалить запись?', 'Удаление записи!', {
          confirmButtonText: 'ОК',
          cancelButtonText: 'Отмена',
          type: 'warning',
        }).then(async () => {
          this.loading = true;
          const res = await usefulDocumentsApi.deleteUsefulDocuments(usefulDocuments.id);
          if (!res.data.succeeded) {
            this.$alert(res.data.resultMessage, 'Ошибка', {
              confirmButtonText: 'OK',
              type: 'warning',
            });
          } else {
            await this.getAdministrationUsefulDocuments();
          }          
          this.loading = false;
        });
      },
      async handleDownload(usefulDocuments) {
        await usefulDocumentsApi.downloadDocument(usefulDocuments.id, usefulDocuments.fileName);
      },
    }
  };
</script>

<style scoped>

</style>
